import React from 'react';

import { Column, Columns, Padding } from '@nib/layout';

import { sharedColumnSpace, BaseFormikProps } from './IwiFormValues';
import RadioButtonFormControl from './controls/RadioButtonFormControl';
import TextboxFormControl from '../FormControl/TextboxFormControl';

interface TenehenehenuiMemberSectionProps extends BaseFormikProps {}

const IwiTribalRegistration = (props: TenehenehenuiMemberSectionProps): JSX.Element => {
  const { touched, values, errors } = props;
  const { tnnRegistration } = values;
  return (
    <Padding top={4}>
      <TextboxFormControl
        id="tnn-registration"
        value={tnnRegistration}
        isTouched={touched.tnnRegistration}
        fieldName="tnnRegistration"
        label="Maniapoto Membership Number"
        error={errors.tnnRegistration}
        valid={!errors.tnnRegistration}
        isEmptyAndOptional={true}
        width="300px"
        {...props}
      />
    </Padding>
  );
};

const TenehenehenuiPersonalDetailsSection = (props: TenehenehenuiMemberSectionProps) => {
  const { touched, values, errors } = props;
  const { tnnStatus } = values;
  const isTnnSet = !(tnnStatus === undefined || tnnStatus === '');

  return (
    <Columns space={sharedColumnSpace} collapseBelow="md">
      <Column>
        <RadioButtonFormControl
          label={''}
          id="tnn-status"
          value={tnnStatus}
          isTouched={touched.tnnStatus}
          fieldName="tnnStatus"
          error={errors.tnnStatus}
          valid={!errors.tnnStatus}
          showButtonStyle={true}
          options={{
            over65: 'Maniapoto Member Kaumātua aged 65+',
            under65: 'Maniapoto Member aged 64 or below',
          }}
          {...props}
        />
        {isTnnSet ? <IwiTribalRegistration {...props} /> : <></>}
      </Column>
    </Columns>
  );
};

export const validateTenehenehenuiDeclaration = (errors, values) => {
  if (!values.tnnRegistration) {
    errors.tnnRegistration = 'Please enter your Maniapoto Member number';
  }
};

export default TenehenehenuiPersonalDetailsSection;
