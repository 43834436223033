import React from 'react';

import { Column, Columns, Padding } from '@nib/layout';

import { sharedColumnSpace, BaseFormikProps } from './IwiFormValues';
import TextboxFormControl from '../FormControl/TextboxFormControl';
import RadioButtonFormControl from './controls/RadioButtonFormControl';
import { FormSection } from './components/FormSection';

interface TenehenehenuiMemberSectionProps extends BaseFormikProps {}

const HealthcareProviderSection = (props: TenehenehenuiMemberSectionProps) => {
  const { touched, values, errors } = props;
  const { gpRegistered, gpRegisteredName } = values;

  return (
    <FormSection title="Healthcare provider details">
      <Columns space={sharedColumnSpace} collapseBelow="md">
        <Column>
          <RadioButtonFormControl
            label={'I am registered with a GP'}
            id="gpRegistered"
            value={gpRegistered}
            isTouched={touched.gpRegistered}
            fieldName="gpRegistered"
            showButtonStyle={true}
            error={errors.gpRegistered}
            valid={!errors.gpRegistered}
            options={{
              yes: 'Yes',
              no: 'No',
            }}
            {...props}
          />
          <Padding top={4}>
            <TextboxFormControl
              id="gp-name"
              value={gpRegisteredName}
              isTouched={touched.gpRegisteredName}
              fieldName="gpRegisteredName"
              label="Name of GP practice"
              error={errors.gpRegisteredName}
              valid={!errors.gpRegisteredName}
              isEmptyAndOptional={false}
              isDisabled={gpRegistered !== 'yes'}
              {...props}
            />
          </Padding>
        </Column>
      </Columns>
    </FormSection>
  );
};

export const validateHealthcareProviderSection = (errors, values) => {
  if (!values.gpRegistered) {
    errors.gpRegistered = 'Please select healthcare provider';
  }

  if (values.gpRegistered === 'yes' && !values.gpRegisteredName) {
    errors.gpRegisteredName = 'Please enter your healthcare provider name';
  }
};

export default HealthcareProviderSection;
