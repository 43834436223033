import React from 'react';

import { Columns, Column, Stack, Padding } from '@nib/layout';

// eslint-disable-next-line import/no-named-as-default
import TextboxFormControl from '../FormControl/TextboxFormControl';
import { BaseFormikProps, sharedColumnSpace } from './IwiFormValues';
import { FormSection } from './components/FormSection';
import InfoBox from '@nib/info-box';
import Copy from '@nib-components/copy';
import Heading from '@nib-components/heading';
import Link from '@nib-components/link';
import CheckboxFormControl from './controls/CheckboxFormControl';
import Modal from '@nib-components/modal';
import { isEmpty } from 'lodash';

const DirectDebitTermsAndConditionsModal = (): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(false);
  const setClosed = () => setIsOpen(false);
  const setOpen = () => setIsOpen(true);
  return (
    <>
      <Modal
        visible={isOpen}
        onClose={setClosed}
        title="Direct Debit Authority Terms and Conditions"
      >
        <Stack space={4}>
          <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="p">
            Specific conditions relating to notices and disputes
          </Heading>
          <Copy>
            I may ask my bank to reverse a direct debit up to 120 calendar days after the debit if:
            <ul>
              <li>
                I don't receive a written notice of the amount and date of each direct debit from
                the initiator, or
              </li>
              <li>
                I receive a written notice but the amount or the date of debiting is different from
                the amount or the date specified on the notice.
              </li>
            </ul>
          </Copy>

          <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="p">
            Notice about direct debits
          </Heading>
          <Copy>
            The initiator is required to give a written notice of the amount and date of each direct
            debit in a series of direct debits no later than the date of the first direct debit in
            the series. The notice is to include:
            <ul>
              <li>the dates of the debits, and </li>
              <li>the amount of each direct debit.</li>
            </ul>
          </Copy>
          <Copy>
            If the bank dishonours a direct debit but the initiator sends the direct debit again
            within 5 business days of the dishonour, the initiator is not required to give you a
            second notice of the amount and date of the direct debit.
          </Copy>

          <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="p">
            Changes to direct debits
          </Heading>
          <Copy>
            If the initiator proposes to change an amount or date of a direct debit specified in the
            notice, the initiator is required to give you notice:
            <ul>
              <li>no less than 30 calendar days before the change, or</li>
              <li>
                if the initiator's bank agrees, no less than 10 calendar days before the change.
              </li>
            </ul>
          </Copy>

          <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="p">
            Your first direct debit
          </Heading>
          <Copy>
            We will send you confirmation including details of when your first payment will be
            deducted from your bank account and the terms and conditions of the arrangement no later
            than 5 business days from now.
          </Copy>
        </Stack>
      </Modal>
      <Copy>
        I have read the <Link onClick={setOpen}>direct debit terms & conditions</Link>.
      </Copy>
    </>
  );
};

interface DirectDebitSectionProps extends BaseFormikProps {}

const DirectDebitFormSection = (props: DirectDebitSectionProps): JSX.Element => {
  const { touched, values, errors } = props;
  const { bankName, bankAccName, bankAccNumber, directDebitConsented } = values;
  return (
    <FormSection id="direct-debit-terms" title="Direct Debit">
      <Columns space={sharedColumnSpace} collapseBelow="md">
        <Column>
          <TextboxFormControl
            id="bankName"
            value={bankName}
            isTouched={touched.bankName}
            fieldName="bankName"
            label="Bank Name"
            error={errors.bankName}
            valid={!errors.bankName}
            {...props}
          />
          <Padding top={4}>
            <TextboxFormControl
              id="bankAccNumber"
              value={bankAccNumber}
              isTouched={touched.bankAccNumber}
              fieldName="bankAccNumber"
              label="Account Number"
              error={errors.bankAccNumber}
              valid={!errors.bankAccNumber}
              {...props}
            />
          </Padding>
        </Column>
        <Column>
          <TextboxFormControl
            id="bankAccName"
            value={bankAccName}
            isTouched={touched.bankAccName}
            fieldName="bankAccName"
            label="Account Name"
            error={errors.bankAccName}
            valid={!errors.bankAccName}
            {...props}
          />
        </Column>
      </Columns>
      <InfoBox title="What to expect?">
        <Copy measure={false}>
          Thanks for choosing to pay your premiums by direct debit. We will advise you of the amount
          due in your welcome pack, which will be emailed once your policy has been accepted. No
          payments will be deducted until after your policy is issued. Monthly payments will be
          deducted on the 20th.
        </Copy>
      </InfoBox>
      <Padding top={4}>
        <Stack space={4}>
          <Heading
            id="dd-terms-and-conditions"
            color="trueGreen"
            size={{ sm: 4, md: 3, lg: 2 }}
            component="p"
          >
            Direct debit terms & conditions
          </Heading>
          <Copy>I confirm I have sole authority to operate this account.</Copy>
          <Copy measure={false}>
            This bank account is NOT held by a Company, a Trust, Estate or any other non Personal
            Account Holder.
          </Copy>
          <Copy measure={false}>
            I haven't cancelled an nib direct debit authority on this bank account within the last 9
            months.
          </Copy>
          <Copy measure={false}>
            I confirm the authority is subject to the acceptor bank's terms and conditions that
            relate to the acceptor's account.
          </Copy>
          <Copy measure={false}>
            I confirm I am happy with nib using the contact information I have provided for all
            communication in relation to this direct debit.
          </Copy>
          <Copy>To cancel your authority or make any changes, please contact us.</Copy>
          <DirectDebitTermsAndConditionsModal />
        </Stack>
      </Padding>
      <CheckboxFormControl
        id={`direct-debit-consented`}
        value={directDebitConsented}
        fieldName="directDebitConsented"
        label={<span>I agree to all of the above</span>}
        isTouched={touched.directDebitConsented}
        valid={!errors.directDebitConsented}
        error={errors.directDebitConsented}
        {...props}
      />
    </FormSection>
  );
};

export const validateDirectDebitSection = (errors, values) => {
  const { whanauDetails, tnnStatus } = values;

  const shouldValidateDirectDebit =
    !isEmpty(whanauDetails) && whanauDetails.some((n) => n.tnnRegistration);

  if (shouldValidateDirectDebit || tnnStatus === 'under65') {
    if (!values.bankName) {
      errors.bankName = 'Please enter your bank name';
    }

    if (!values.bankAccNumber) {
      errors.bankAccNumber = 'Please enter your bank account number';
    }

    if (!values.bankAccName) {
      errors.bankAccName = 'Please enter your bank account name';
    }

    if (!values.directDebitConsented) {
      errors.directDebitConsented = 'You must agree to Direct debit terms & conditions';
    }
  }
};

export default DirectDebitFormSection;
