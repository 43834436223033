import React from 'react';

import { Column, Columns } from '@nib/layout';

import { sharedColumnSpace, BaseFormikProps } from './IwiFormValues';
import TextboxFormControl from '../FormControl/TextboxFormControl';
import { FormSection } from './components/FormSection';
import Copy from '@nib-components/copy';
import DateTextboxFormControl from './controls/DateTextboxFormControl';

interface TenehenehenuiMemberSectionProps extends BaseFormikProps {}

const SupportPersonSection = (props: TenehenehenuiMemberSectionProps) => {
  const { touched, values, errors } = props;
  const { supportPersonName, supportPersonDob, supportPersonEmail, supportPersonPhone } = values;

  return (
    <FormSection title="Support person">
      <Copy measure={false}>
        Would you like to add a support person that can assist with your policy and claims?
      </Copy>
      <Columns space={sharedColumnSpace} collapseBelow="md">
        <Column width={{ md: '1/3', lg: '2/5' }}>
          <TextboxFormControl
            id="support-person-name"
            value={supportPersonName}
            isTouched={touched.supportPersonName}
            fieldName="supportPersonName"
            label="Full Name"
            error={errors.supportPersonName}
            valid={!errors.supportPersonName}
            {...props}
          />
        </Column>
        <Column>
          <DateTextboxFormControl
            id="support-date-of-birth"
            value={supportPersonDob}
            isTouched={touched.supportPersonDob}
            fieldName="supportPersonDob"
            label="Date of Birth"
            error={errors.supportPersonDob}
            valid={!errors.supportPersonDob}
            isEmptyAndOptional={true}
            {...props}
          />
        </Column>
      </Columns>
      <Columns space={sharedColumnSpace} collapseBelow="md">
        <Column width="1/3">
          <TextboxFormControl
            id="support-person-phone"
            value={supportPersonPhone}
            isTouched={touched.supportPersonPhone}
            fieldName="supportPersonPhone"
            label="Phone"
            isEmptyAndOptional={true}
            error={errors.supportPersonPhone}
            valid={!errors.supportPersonPhone}
            {...props}
          />
        </Column>
        <Column>
          <TextboxFormControl
            id="support-person-email"
            value={supportPersonEmail}
            isTouched={touched.supportPersonEmail}
            fieldName="supportPersonEmail"
            autoComplete="supportPersonEmail"
            label="Email"
            error={errors.supportPersonEmail}
            valid={!errors.supportPersonEmail}
            {...props}
          />
        </Column>
      </Columns>
    </FormSection>
  );
};

export default SupportPersonSection;
