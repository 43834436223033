import React from 'react';

import Container from '@nib-components/container';
import Divider from '@nib-components/divider';
import { Padding, Stack } from '@nib/layout';
import { PrimaryButton } from '@nib-components/button';
import Link from '@nib-components/link';
import Alert from '@nib-components/alert';
import { CommErrorMessage } from '../shared/ContactUsApiCommon';

import CheckboxFormControl from './controls/CheckboxFormControl';
import { scrollToError } from '../../utils/form-validation';
import { BaseFormikProps } from './IwiFormValues';
import Copy from '@nib-components/copy';
import Heading from '@nib-components/heading';

interface AgreeAndSubmitFormSectionProps extends BaseFormikProps {}

const TnnAgreeAndSubmitFormSection = (props: AgreeAndSubmitFormSectionProps) => {
  const { touched, values, isSubmitting, status, errors } = props;
  const { conditionsAccepted } = values;

  return (
    <Container>
      <Stack space={4}>
        <Divider />
        <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="p">
          Declaration
        </Heading>
        <Copy measure={false}>
          To be a part of the nib Te Nehenehenui Everyday Health Plan programme and to complete the
          enrolment process, we need your consent for:
          <ul>
            <li>
              nib to share some of your personal information with Te Nehenehenui (e.g. your name,
              contact details, high level details about the claims you have made, and your frequency
              of claims, any suspicious activity and if there is a cancellation of your policy) to
              enable Te Nehenehenui to keep their Maniapoto Membership Register up to date, and
              ensure you are making the most out of your cover and ensure the policy is being used
              for proper purposes.
            </li>
            <li>
              Te Nehenehenui to share some of your information with nib (e.g. your name and contact
              details) to ensure your information is up to date and for nib to share communications
              with you.
            </li>
            <li>
              Te Nehenehenui to share some of your information with other related entities of Te
              Nehenehenui, including Waihikurangi Trust and Ahuahu Group Limited to inform you of
              any activities or initiatives relevant to Maniapoto me ōna hapū maha.
            </li>
          </ul>
          If you do not provide your consent, we cannot enrol you in the nib Te Nehenehenui Everyday
          Health Plan.
        </Copy>
        <Padding vertical={4}>
          <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="p">
            Knowing your privacy rights
          </Heading>
        </Padding>
        <Copy measure={false}>
          You have rights under the Privacy Act regarding your private information. At any time, you
          can access, check and correct, or ask for a copy of any information, nib or Te Nehenehenui
          have about you.
        </Copy>

        <Copy measure={false}>
          For a copy of nib’s privacy policy, including our contact details if you want to access
          your personal information, please visit our Privacy policy webpage{' '}
          <Link href="https://www.nib.co.nz/privacy-policy" target="__blank">
            https://www.nib.co.nz/privacy-policy
          </Link>
          .
        </Copy>

        <Padding top={4}>
          <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="p">
            Consent
          </Heading>
        </Padding>

        <CheckboxFormControl
          id={`conditions-accepted`}
          value={conditionsAccepted}
          fieldName="conditionsAccepted"
          label={
            <span>
              Yes I give consent for nib and Te Nehenehenui to share relevant information about me,
              I’ve read and agree to the privacy policy for myself and everyone on this enrolment
              form and I have authority to provide personal information about everyone on this form.
            </span>
          }
          width="100%"
          isTouched={touched.conditionsAccepted}
          valid={!errors.conditionsAccepted}
          error={errors.conditionsAccepted}
          {...props}
        />
        <Padding top={4}>
          <>
            {status && !status.submitSucceeded && status.errorMessage && (
              <Alert type="error" title="Oops, something went wrong" fullWidth={false}>
                <CommErrorMessage />
              </Alert>
            )}
            <PrimaryButton type="submit" disabled={isSubmitting} onClick={() => scrollToError()}>
              Submit registration
            </PrimaryButton>
          </>
        </Padding>
      </Stack>
    </Container>
  );
};

export const validateTnnAgreeAndSubmitFormSection = (errors, values) => {
  if (!values.conditionsAccepted) {
    errors.conditionsAccepted = 'You must agree to the privacy policy';
  }
};

export default TnnAgreeAndSubmitFormSection;
