import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { Formik } from 'formik';
import { RouteComponentProps } from '@reach/router';
import { graphql } from 'gatsby';

import Form from '@nib-components/form';
import Copy from '@nib-components/copy';
import Modal from '@nib-components/modal';
import { PrimaryButton } from '@nib-components/button';
import { Section, Stack } from '@nib/layout';

import HeroPanel from '../../components/HeroPanel';
import {
  BaseFormikProps,
  getTnnInitialValues,
  TnnEnrolmentFormValues,
} from '../../components/nwo-form/IwiFormValues';
import TenehenehenuiPersonalDetailsSection, {
  validateTenehenehenuiDeclaration,
} from '../../components/nwo-form/TnnPersonalDetailsSection';
import AddressFormSection, {
  validateAddressDetails,
} from '../../components/nwo-form/AddressFormSection';
import ContactDetailsFormSection, {
  validateContactDetails,
} from '../../components/nwo-form/ContactDetailsFormSection';
import PersonalDetailsFormSection, {
  validatePersonalDetails,
} from '../../components/nwo-form/PersonalDetailsFormSection';

import Layout from '../../components/Layout';
import { ContentfulDocument } from '../../constructs/models';
import metrics from '../../metrics';

import desktop from '../../img/tenehenehenui/hero/desktop.png';
import desktop2x from '../../img/tenehenehenui/hero/desktop@2x.png';
import mobile from '../../img/tenehenehenui/hero/mobile.png';
import mobile2x from '../../img/tenehenehenui/hero/mobile@2x.png';
import tablet from '../../img/tenehenehenui/hero/tablet.png';
import tablet2x from '../../img/tenehenehenui/hero/tablet@2x.png';

import { postContactUsEmail } from '../../components/shared/ContactUsApiService';
import { generateTnnEmailBody } from '../../components/shared/contactUsContent/tnnEnrolmentEmail';
import { HeroPanelCopy } from '../../components/styledComponents';
import { FormSection } from '../../components/nwo-form/components/FormSection';
import HealthcareProviderSection, {
  validateHealthcareProviderSection,
} from '../../components/nwo-form/HealthcareProviderSection';
import SupportPersonSection from '../../components/nwo-form/SupportPersonSection';
import WhanauDetailsSection, {
  validateWhanauDetailsSection,
} from '../../components/nwo-form/WhanauDetailsSection';
import TnnAgreeAndSubmitFormSection, {
  validateTnnAgreeAndSubmitFormSection,
} from '../../components/nwo-form/TnnAgreeAndSubmitFormSection';
import DirectDebitFormSection, {
  validateDirectDebitSection,
} from '../../components/nwo-form/DirectDebitSection';

import NominatedBeneficiary, {
  validateNominatedBeneficiary,
} from '../../components/nwo-form/NominatedBeneficiary';

export const EnrolPageQuery = graphql`
  query EnrolPageQuery {
    policy: allContentfulDocument(filter: { name: { eq: "NWO Policy Document" } }, limit: 1) {
      edges {
        node {
          document {
            file {
              fileName
              url
            }
          }
        }
      }
    }
  }
`;

const title = 'Te Nehenehenui Health Insurance';
const metaDescription = 'The Te Nehenehenui Health Insurance, which is being provided.';

interface EnrolProps extends RouteComponentProps, EnrolDocumentProps {}

interface GraphQLEnrolDocumentNode {
  edges: ContentfulEnrolDocumentEdgeShape[];
}

interface ContentfulEnrolDocumentEdgeShape {
  node: {
    document: ContentfulDocument;
  };
}

interface EnrolDocumentProps {
  data: {
    policy: GraphQLEnrolDocumentNode;
  };
}

const TNNEnrol = (props: EnrolProps): JSX.Element => {
  const policyDocument = props?.data?.policy?.edges?.[0]?.node?.document;
  return (
    <Layout>
      <div>
        <Helmet>
          <title>{title} | nib</title>
          <meta name="description" content={metaDescription} />
        </Helmet>
        <HeroPanelSection />
        <FormDescriptionSection policyDocument={policyDocument} />
        <FormikSection />
      </div>
    </Layout>
  );
};

const HeroPanelSection = (): JSX.Element => {
  return (
    <HeroPanel
      title="Te Nehenehenui"
      variation="condensed"
      images={{
        desktop: desktop,
        desktop2x: desktop2x,
        mobile: mobile,
        mobile2x: mobile2x,
        tablet: tablet,
        tablet2x: tablet2x,
      }}
    >
      <HeroPanelCopy>Health Insurance Enrolment</HeroPanelCopy>
      <PrimaryButton href="/tenehenehenui">Learn more</PrimaryButton>
    </HeroPanel>
  );
};

interface FormDescriptionSectionProps {
  policyDocument: ContentfulDocument;
}

const FormDescriptionSection = ({ policyDocument }: FormDescriptionSectionProps): JSX.Element => {
  return (
    <Section>
      <Stack space={4}>
        <Copy measure={false}>
          Complete all sections of this form to register into the Te Nehenehenui Everyday Health
          Plan. To be eligible for cover at no cost in the first year of enrolment you must be
          registered on the Maniapoto Membership Register and be 65 years or older. If you are iwi
          registered and under 65 years old you can still register at your own cost.
        </Copy>
        <Copy measure={false}>
          Once registration is confirmed by Te Nehenehenui, you will be enrolled into the Te
          Nehenehenui Everyday Health Plan and will receive your welcome pack stating your policy
          start date (which is the date you can start seeking treatment for eligible claims), your
          Acceptance certificate and Policy document.
        </Copy>
      </Stack>
    </Section>
  );
};

const onSubmit = (values, { setSubmitting, setStatus, resetForm }, setShowSuccessModal) => {
  const commType = 'tenehenehenui';
  const commDescription = 'Member enrolment - nib';
  postContactUsEmail(commType, commDescription, '', '', [], generateTnnEmailBody(values))
    .then(function () {
      resetForm({});
      setStatus({
        submitSucceeded: true,
      });
      // Need to pass this function in so that you can set the state
      setShowSuccessModal(true);
    })
    .catch(function (error) {
      setStatus({
        submitSucceeded: false,
        errorMessage: error.message,
      });
    });
};

const validate = (values) => {
  const errors: any = {};
  validateTenehenehenuiDeclaration(errors, values);
  validatePersonalDetails(errors, values);
  validateContactDetails(errors, values);
  validateAddressDetails(errors, values);
  validateHealthcareProviderSection(errors, values);
  validateNominatedBeneficiary(errors, values);
  validateWhanauDetailsSection(errors, values);
  validateDirectDebitSection(errors, values);
  validateTnnAgreeAndSubmitFormSection(errors, values);
  return errors;
};

const FormikSection = (): JSX.Element => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  return (
    <div>
      <Modal title="Great!" visible={showSuccessModal} onClose={() => setShowSuccessModal(false)}>
        <div>
          Once registration is confirmed, you will be enrolled into the Te Nehenehenui Health
          Insurance plan, and will receive your welcome pack including your Acceptance certificate
          and Policy document.
        </div>
      </Modal>
      <Section background="sunsetPink60">
        <Formik
          initialValues={getTnnInitialValues()}
          validate={validate}
          validateOnBlur={true}
          validateOnChange={false}
          onSubmit={(values, formikHelpers) =>
            onSubmit(values, { ...formikHelpers }, setShowSuccessModal)
          }
          component={EnrolmentFormInner}
        />
      </Section>
    </div>
  );
};

interface EnrolmentFormInnerProps extends BaseFormikProps, TnnEnrolmentFormValues {}

const Over65Form = (props: EnrolmentFormInnerProps): JSX.Element => {
  const { whanauDetails } = props.values;
  const requiresDirectDebit = whanauDetails.length > 0 && !!whanauDetails[0].tnnRegistration;

  return (
    <>
      <ContactDetailsFormSection {...props} />
      <FormSection title="Address">
        <AddressFormSection {...props} />
      </FormSection>
      <HealthcareProviderSection {...props} />
      <NominatedBeneficiary {...props} />
      <SupportPersonSection {...props} />
      <WhanauDetailsSection {...props} />
      {requiresDirectDebit && <DirectDebitFormSection {...props} />}
    </>
  );
};

const Under65Form = (props: EnrolmentFormInnerProps): JSX.Element => {
  return (
    <>
      <ContactDetailsFormSection {...props} />
      <FormSection title="Address">
        <AddressFormSection {...props} />
      </FormSection>
      <HealthcareProviderSection {...props} />
      <NominatedBeneficiary {...props} />
      <SupportPersonSection {...props} />
      <WhanauDetailsSection {...props} />
      <DirectDebitFormSection {...props} />
    </>
  );
};

const EnrolmentFormInner = (props: EnrolmentFormInnerProps): JSX.Element => {
  const { tnnStatus } = props.values;
  const isTnnSet = !(tnnStatus === undefined || tnnStatus === '');

  return (
    <>
      <Form
        id="tnnEnrolmentForm"
        name="tnnEnrolmentForm"
        containerWidth={null}
        onSubmit={props.handleSubmit}
        style={{ backgroundColor: 'rgba(0,0,0,0)' }}
      >
        <FormSection title="Personal Details">
          <TenehenehenuiPersonalDetailsSection {...props} />
          {isTnnSet ? <PersonalDetailsFormSection {...props} /> : <></>}
        </FormSection>
        {isTnnSet && tnnStatus === 'over65' ? <Over65Form {...props} /> : <></>}
        {isTnnSet && tnnStatus === 'under65' ? <Under65Form {...props} /> : <></>}
        {isTnnSet ? <TnnAgreeAndSubmitFormSection {...props} /> : <></>}
      </Form>
    </>
  );
};

export default metrics({ pageName: 'nwo' })(TNNEnrol);
