import React from 'react';

import { isEmpty, pullAt } from 'lodash';
import { Box, Column, Columns, Inline, Padding, Stack } from '@nib/layout';
import Divider from '@nib-components/divider';

import {
  sharedColumnSpace,
  BaseFormikProps,
  addWhanauDetails,
  TnnEnrolmentFormValues,
} from './IwiFormValues';
import TextboxFormControl from '../FormControl/TextboxFormControl';
import { FormSection } from './components/FormSection';
import Copy from '@nib-components/copy';
import DateTextboxFormControl from './controls/DateTextboxFormControl';
import { isInvalidDate } from '../../utils/form-validation';
import { SecondaryButton } from '@nib-components/button';
import { AddSystemIcon } from '@nib/icons';

interface TenehenehenuiMemberSectionProps extends BaseFormikProps {}

const WhanauDetailsList = (props: TenehenehenuiMemberSectionProps): JSX.Element => {
  const { touched, values, errors, setFieldValue } = props;
  const { whanauDetails } = values;

  const isValidField = (errors: any, index: number, fieldName: string): boolean => {
    if (errors?.whanauDetails?.[index]?.[fieldName]) {
      return false;
    }
    return true;
  };

  const getErrorMessage = (errors: any, index: number, fieldName: string): string | undefined => {
    if (errors?.whanauDetails?.[index]?.[fieldName]) {
      return errors.whanauDetails[index][fieldName];
    }
    return undefined;
  };

  return (
    <>
      {whanauDetails.map((memberDetails, index) => {
        const { tnnRegistration, name, dateOfBirth, gender, phone, email } = memberDetails;
        const removeMember = () => {
          pullAt(whanauDetails, index);
          setFieldValue('whanauDetails', whanauDetails);
        };

        return (
          <Stack space={4} key={index}>
            <Columns space={sharedColumnSpace} collapseBelow="md">
              <Column width={'1/3'}>
                <TextboxFormControl
                  id={`tnn-whanau-registration-${index}`}
                  value={tnnRegistration}
                  isTouched={touched?.whanauDetails?.[index]?.tnnRegistration}
                  fieldName={`whanauDetails[${index}].tnnRegistration`}
                  label="Maniapoto Membership Number"
                  valid={isValidField(errors, index, 'tnnRegistration')}
                  error={getErrorMessage(errors, index, 'tnnRegistration')}
                  isEmptyAndOptional={true}
                  {...props}
                />
              </Column>
              <Column width={{ md: '1/3', lg: '2/5' }}>
                <TextboxFormControl
                  id={`whanauName-${index}`}
                  value={name}
                  isTouched={touched?.whanauDetails?.[index]?.name}
                  fieldName={`whanauDetails[${index}].name`}
                  label="Full Name"
                  valid={isValidField(errors, index, 'name')}
                  error={getErrorMessage(errors, index, 'name')}
                  {...props}
                />
              </Column>
              <Column>
                <DateTextboxFormControl
                  id={`whanauDob-${index}`}
                  value={dateOfBirth}
                  isTouched={touched?.whanauDetails?.[index]?.dateOfBirth}
                  fieldName={`whanauDetails[${index}].dateOfBirth`}
                  label="Date of Birth"
                  valid={isValidField(errors, index, 'dateOfBirth')}
                  error={getErrorMessage(errors, index, 'dateOfBirth')}
                  isEmptyAndOptional={true}
                  {...props}
                />
              </Column>
            </Columns>
            <Columns space={sharedColumnSpace} collapseBelow="md">
              <Column width="1/3">
                <TextboxFormControl
                  id={`whanauGender-${index}`}
                  value={gender}
                  isTouched={touched?.whanauDetails?.[index]?.gender}
                  fieldName={`whanauDetails[${index}].gender`}
                  label="Gender"
                  isEmptyAndOptional={true}
                  valid={isValidField(errors, index, 'gender')}
                  error={getErrorMessage(errors, index, 'gender')}
                  {...props}
                />
              </Column>
              <Column>
                <Column width="1/3">
                  <TextboxFormControl
                    id={`whanauPhone-${index}`}
                    value={phone}
                    isTouched={touched?.whanauDetails?.[index]?.phone}
                    fieldName={`whanauDetails[${index}].phone`}
                    label="Phone"
                    isEmptyAndOptional={true}
                    valid={isValidField(errors, index, 'phone')}
                    error={getErrorMessage(errors, index, 'phone')}
                    {...props}
                  />
                </Column>
              </Column>
            </Columns>
            <Padding vertical={4}>
              <TextboxFormControl
                id={`whanauEmail-${index}`}
                value={email}
                isTouched={touched?.whanauDetails?.[index]?.email}
                fieldName={`whanauDetails[${index}].email`}
                label="Email"
                valid={isValidField(errors, index, 'email')}
                error={getErrorMessage(errors, index, 'email')}
                {...props}
              />
            </Padding>
            <Box textAlign="right">
              <SecondaryButton onClick={removeMember}>Remove</SecondaryButton>
            </Box>
            {index < whanauDetails.length && (
              <Padding bottom={4}>
                <Divider />
              </Padding>
            )}
          </Stack>
        );
      })}
    </>
  );
};

const WhanauDetailsSection = (props: TenehenehenuiMemberSectionProps) => {
  const { values, setFieldValue } = props;
  const { whanauDetails } = values;
  const AddMember = () => {
    const newWhanauDetails = [...whanauDetails, addWhanauDetails()];
    console.log(newWhanauDetails);
    setFieldValue('whanauDetails', newWhanauDetails);
  };

  return (
    <FormSection title="Additional whānau details">
      <Copy measure={false}>
        If you want to add whānau (partner/spouse or dependants under 21) to your policy please fill
        out their details below. Please note adding this is at your own cost.
      </Copy>
      <Copy measure={false}>
        A spouse/partner is defined as married or in a defacto relationship.
      </Copy>
      <WhanauDetailsList {...props} />
      <SecondaryButton onClick={AddMember} disabled={whanauDetails.length >= 20}>
        <Inline space={3} verticalAlign="center" align="center">
          <AddSystemIcon />
          <Copy>Add additional member</Copy>
        </Inline>
      </SecondaryButton>
    </FormSection>
  );
};

// Validates only when any of the values given, otherwise section is optional
export const validateWhanauDetailsSection = (errors, values: TnnEnrolmentFormValues) => {
  const membersArrayErrors: any[] = [];
  const { whanauDetails } = values;

  whanauDetails.forEach((member, index) => {
    const whanauDetailsErrors: any = {};

    /*
     * The form on initial render displays a row of non-member inputs that are optional
     * unless one of the inputs has been correctly filled in.
     */
    const shouldValidateMember = !(
      isEmpty(member.tnnRegistration) &&
      isEmpty(member.name) &&
      isInvalidDate(member.dateOfBirth) &&
      isEmpty(member.gender)
    );

    if (shouldValidateMember) {
      if (!member.tnnRegistration) {
        whanauDetailsErrors.tnnRegistration = 'Please enter your whanau Maniapoto Member number';
      }

      if (isInvalidDate(member.dateOfBirth)) {
        whanauDetailsErrors.dateOfBirth = 'Please enter date of birth';
      }

      if (isEmpty(member.gender)) {
        whanauDetailsErrors.gender = 'Please select a gender';
      }

      if (isEmpty(member.name)) {
        whanauDetailsErrors.name = 'Please provide the full name';
      }

      if (isEmpty(member.phone)) {
        whanauDetailsErrors.phone = 'Please provide phone';
      }

      if (isEmpty(member.email)) {
        whanauDetailsErrors.email = 'Please provide email';
      }
    }

    if (Object.keys(whanauDetailsErrors).length !== 0) {
      membersArrayErrors[index] = whanauDetailsErrors;
    }
  });

  if (membersArrayErrors.length) {
    errors.whanauDetails = membersArrayErrors;
  }
};

export default WhanauDetailsSection;
