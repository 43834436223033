import React from 'react';

import { Column, Columns } from '@nib/layout';

import { sharedColumnSpace, BaseFormikProps, TnnEnrolmentFormValues } from './IwiFormValues';
import TextboxFormControl from '../FormControl/TextboxFormControl';
import { FormSection } from './components/FormSection';
import Copy from '@nib-components/copy';
import DateTextboxFormControl from './controls/DateTextboxFormControl';

import {
  isEmpty,
  isInvalidDate,
  validateAddress,
  validateEmail,
  validatePhoneNumbers,
} from '../../utils/form-validation';
import AddressFormSection from './AddressFormSection';

interface NominatedBeneficiaryProps extends BaseFormikProps, TnnEnrolmentFormValues {}

const NominatedBeneficiary = (props: NominatedBeneficiaryProps) => {
  const { touched, values, errors } = props;
  const {
    beneficiaryName,
    beneficiaryDob,
    beneficiaryRelationship,
    beneficiaryEmail,
    beneficiaryPhone,
  } = values;

  return (
    <FormSection title="Beneficiary for Tangihanga Benefit">
      <Copy measure={false}>
        If you’re over the age of 16, you can choose a beneficiary who will receive any Tangihanga
        Benefit claim payment if you die and you’re the only policyowner. If you die and there are
        other policyowners, we’ll pay this benefit to them. If you are the only policyowner and the
        beneficiary was not alive to receive the payment, or you choose not to name a beneficiary,
        it would be paid to your estate or paid to Te Nehenehenui to administer.
      </Copy>
      <Columns space={sharedColumnSpace} collapseBelow="md">
        <Column width={{ md: '1/3', lg: '2/5' }}>
          <TextboxFormControl
            id="beneficiary-name"
            value={beneficiaryName}
            isTouched={touched.beneficiaryName}
            fieldName="beneficiaryName"
            label="Full Name"
            error={errors.beneficiaryName}
            valid={!errors.beneficiaryName}
            {...props}
          />
        </Column>
        <Column>
          <DateTextboxFormControl
            id="beneficiary-date-of-birth"
            value={beneficiaryDob}
            isTouched={touched.beneficiaryDob}
            fieldName="beneficiaryDob"
            label="Date of Birth (must be aged 16 or older)"
            error={errors.beneficiaryDob}
            valid={!errors.beneficiaryDob}
            isEmptyAndOptional={false}
            {...props}
          />
        </Column>
      </Columns>
      <Columns space={sharedColumnSpace} collapseBelow="md">
        <Column width="1/3">
          <TextboxFormControl
            id="beneficiary-relationship"
            value={beneficiaryRelationship}
            isTouched={touched.beneficiaryRelationship}
            fieldName="beneficiaryRelationship"
            label="Relationship to you"
            isEmptyAndOptional={true}
            error={errors.beneficiaryRelationship}
            valid={!errors.beneficiaryRelationship}
            {...props}
          />
        </Column>
      </Columns>
      <Columns space={sharedColumnSpace} collapseBelow="md">
        <Column width="1/3">
          <TextboxFormControl
            id="beneficiary-phone"
            value={beneficiaryPhone}
            isTouched={touched.beneficiaryPhone}
            fieldName="beneficiaryPhone"
            label="Phone"
            isEmptyAndOptional={true}
            error={errors.beneficiaryPhone}
            valid={!errors.beneficiaryPhone}
            {...props}
          />
        </Column>
        <Column>
          <TextboxFormControl
            id="beneficiary-email"
            value={beneficiaryEmail}
            isTouched={touched.beneficiaryEmail}
            fieldName="beneficiaryEmail"
            autoComplete="beneficiaryEmail"
            label="Email"
            error={errors.beneficiaryEmail}
            valid={!errors.beneficiaryEmail}
            {...props}
          />
        </Column>
      </Columns>
      <AddressFormSection {...props} prefix="beneficiary" />
      <Copy measure={false}>
        We recommend you review your beneficiary from time to time, especially as your life
        circumstances change – this includes things like getting married or divorced, and having
        tamariki. We will only use personal information about a beneficiary to the extent necessary
        for the purpose of managing a claim.
      </Copy>
    </FormSection>
  );
};

export const validateNominatedBeneficiary = (errors, values) => {
  if (isEmpty(values.beneficiaryName)) {
    return;
  }

  if (isEmpty(values.beneficiaryRelationship)) {
    errors.beneficiaryRelationship = 'Please enter beneficiary relationship to you';
  }

  if (isInvalidDate(values.beneficiaryDob)) {
    errors.beneficiaryDob = 'Please enter beneficiary date of birth';
  }

  validatePhoneNumbers(errors, values, 'beneficiaryPhone');
  validateEmail(errors, values, 'beneficiaryEmail');

  validateAddress(errors, values, 'beneficiaryNumberAndStreet', 'beneficiaryNumberAndStreet');
  validateAddress(errors, values, 'beneficiaryCity', 'beneficiaryCity');
  validateAddress(errors, values, 'beneficiaryCountry', 'beneficiaryCountry');

  // validateAddressDetails(errors, values, 'beneficiary');
};

export default NominatedBeneficiary;
