import { template } from 'lodash';

const compiledNwoTemplate = template(`
  <div>
    <div>Kia ora,</div>
    <br />
    <div>
      A Te Nehenehenui individual has completed the online nib registration form.
      Please confirm registration, and if applicable add them to the signup sheet to be sent to nib.
      Please note a policy will only be set up once received on the signup sheet from Te Nehenehenui.
    </div>

    <br />
    <div><b>Enrolment Details</b></div>

    <div>Title, FirstName, MiddleName, LastName, DOB, Gender, PreferredGender, HomePhone, MobilePhone, WorkPhone, EmailAddress, Address, Suburb, City, Country, Postcode, Referrer, Status, ITR</div>
    <div><%- enrolment %></b></div>

    <br />
    <div><b>Healthcare provider</b></div>
    <div>Name <%- healthcareProviderDetails %></div>

    <br />
    <div><b>Beneficiary</b></div>
    <div>Name, Relationship, DOB, Phone, Email, Street, Suburb, City, Country, Postcode </div>
    <div><%- beneficiaryDetails %></div>

    <br />
    <div><b>Support person</b></div>
    <div>Name, DOB, Phone, Email </div>
    <div><%- supportPersonDetails %></div>

    <br />
    <div><b>Additional Whanau details</b></div>

    <div>ITR, Name, DOB, Gender, Phone, Email </div>
    <br />
    <% whanauDetails.forEach(function(memberData) { %>
      <div><%- memberData %></div>
      <br />
    <% }); %>

    <br />
    <div><b>Direct Debit</b></div>
    <div>Bank Number, Accout Name, Account Number, Direct Debit Conditions Accepted</div>
    <div><%- directDebitDetails %></div>

    <br />
    <div><b>Additional Details</b></div>
    <div>Reference Id</div>
    <div><%- referenceId %></b></div>
    <br />

    <div>Conditions accepted</div>
    <div><%- conditionsAccepted %></b></div>

    <br />
    <div>Ngā mihi</div>
    <div>The team at nib</div>
  </div>
`);

const fieldsEnrolmentDetails = [
  'tnnStatus',
  'tnnRegistration',
  'title',
  'firstName',
  'middleName',
  'surname',
  'dateOfBirth',
  'gender',
  'preferredGender',
  'homePhone',
  'mobilePhone',
  'workPhone',
  'email',
  'numberAndStreet',
  'suburb',
  'city',
  'country',
  'postCode',
  'referralName',
];

const fieldsHealthcareProvider = ['gpRegisteredName'];

const fieldsSupportPerson = [
  'supportPersonName',
  'supportPersonDob',
  'supportPersonPhone',
  'supportPersonEmail',
];

const fieldsBeneficiary = [
  'beneficiaryName',
  'beneficiaryRelationship',
  'beneficiaryDob',
  'beneficiaryPhone',
  'beneficiaryEmail',
  'beneficiaryNumberAndStreet',
  'beneficiarySuburb',
  'beneficiaryCity',
  'beneficiaryCountry',
  'beneficiaryPostCode',
  'beneficiaryAutoFillAddress',
];

const fieldDirectDebitDetails = [
  'bankName',
  'bankAccName',
  'bankAccNumber',
  'directDebitConsented',
];

const toCommaSeparatedValues = (data, whitelistFilter) => {
  const dataEntries = Object.entries(data || []);
  return whitelistFilter
    .reduce((res, whitelistKey) => {
      const kv = dataEntries.find(([key]) => key === whitelistKey);
      if (kv) {
        res.push(kv[1]);
      }
      return res;
    }, [])
    .join(', ');
};

const toListOfCommaSeparatedValues = (data: object[]) =>
  data.reduce<string[]>((details, data) => {
    let text = Object.values(data || []).join(', ');
    details.push(text);
    return details;
  }, [] as string[]);

export const generateTnnEmailBody = (data) => (referenceId) => {
  const enrolment = toCommaSeparatedValues(data, fieldsEnrolmentDetails);
  const healthcareProviderDetails = toCommaSeparatedValues(data, fieldsHealthcareProvider);
  const supportPersonDetails = toCommaSeparatedValues(data, fieldsSupportPerson);
  const whanauDetails = toListOfCommaSeparatedValues(data.whanauDetails);
  const beneficiaryDetails = toCommaSeparatedValues(data, fieldsBeneficiary);
  const directDebitDetails = toCommaSeparatedValues(data, fieldDirectDebitDetails);
  const conditionsAccepted = !!data.conditionsAccepted;

  return compiledNwoTemplate({
    referenceId,
    conditionsAccepted,
    enrolment,
    healthcareProviderDetails,
    beneficiaryDetails,
    supportPersonDetails,
    whanauDetails,
    directDebitDetails,
  });
};
